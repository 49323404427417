<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    width?: number | "auto";
    height?: number | "auto";
    closeable?: boolean;
    show: boolean;
    autoclose?: boolean;
    bgcolor?: string;
    nopadding?: boolean;
  }>(),
  {
    width: "auto",
    height: "auto",
    closeable: true,
    show: true,
    autoclose: true,
    bgcolor: "bg-white",
    nopadding: false,
  },
);

const emit = defineEmits(["close"]);

// -----------------------
// reactive properties
// -----------------------
const content = ref(null);

// -----------------------
// computed properties
// -----------------------
/*
const contentStyle = computed(() => {
  let styles = `width: ${props.width}px; max-width: 100%;`;

  if (props.height !== "auto") {
    styles += `;min-height: ${props.height}px`;
  }

  return styles;
});
*/
const contentStyle = computed(() => {
  let styles = `display: inline-block;`; // Ensures the width adapts to content

  if (props.width !== "auto") {
    styles += `width: ${props.width}px;`;
  }

  if (props.height !== "auto") {
    styles += `min-height: ${props.height}px;`;
  }

  return styles;
});

const contentClass = computed(() => {
  const classes: string[] = [
    "content",
    "relative",
    "max-w-full",
    "max-h-screen",
    "overflow-y-auto",
  ];

  if (!props.nopadding) {
    classes.push("m-3");
    classes.push("mx-auto");
    classes.push("my-[10px]");
    classes.push("p-[25px]");
  }

  classes.push(props.bgcolor);

  return classes.join(" ");
});

// -----------------------
// hooks
// -----------------------
onUpdated(() => {
  // Disable body overflow when modal is visible
  const classList = document.body.classList;

  if (props.show) {
    classList.add("overflow-hidden");

    // Close on escape
    document.body.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Escape") {
          emit("close");
        }
      },
      { once: true },
    );
  } else {
    classList.remove("overflow-hidden");
  }
});

if (import.meta.client) {
  watch(
    () => props.show,
    (newValue: any) => {
      if (newValue) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    { immediate: true },
  );

  // Ensure cleanup when the component is unmounted
  onUnmounted(() => {
    document.body.classList.remove("overflow-hidden");
  });
}

// -----------------------
// helper functions
// -----------------------
const handleClick = (e: PointerEvent) => {
  if (!props.autoclose) return;

  let el: HTMLElement = e.target;

  while (el && el.tagName.toLowerCase() !== "body") {
    if (el === content.value) {
      return;
    }

    el = el.parentNode;
  }

  emit("close");
};
</script>
<template>
  <div
    v-if="show"
    class="z-modal fixed w-full h-full top-0 left-0 backdrop-blur overflow-y-auto base-modal drop-shadow"
    @click="handleClick"
  >
    <div class="w-full h-full flex items-center justify-center">
      <div ref="content" :class="contentClass" :style="contentStyle">
        <button
          v-if="closeable"
          class="w-[12px] h-[12px] absolute top-[12px] right-[12px] flex justify-center items-center"
          @click="$emit('close')"
        >
          <i class="m-icon-cancel ml-[1px] text-xs"></i>
        </button>
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>
